.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}
.logo {
  display: flex; /* 使用Flexbox布局来使子元素并列 */
  align-items: center; /* 垂直居中子元素 */
  /* 其他你想要的样式 */
}
.img{
 width: 100%;
 height: 45%;
}
.box{
  height: 30%;
  width: 60%;
  margin: 0 auto;
}
.logo-image {
  height: 40px;
  width: 40px;
  display: inline-block; /* 通常不需要，因为img默认是内联元素 */
  /* 设置图片的宽高或其他样式 */
}
.logo-text {
  display: inline-block; /* 使span并列显示 */
  font-weight: bold; /* 这会使字体加粗 */
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
